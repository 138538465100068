import React from "react";
import styled from "styled-components/macro";
import { MoreVertical } from "react-feather";

import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { spacing } from "@mui/system";

import TimeFormat from "../../../utils/timeFormat";

const Card = styled(MuiCard)(spacing);

// const Chip = styled(MuiChip)`
//   height: 20px;
//   padding: 4px 0;
//   font-size: 90%;
//   background-color: ${(props) =>
//     props.theme.palette[props.color ? props.color : "primary"].light};
//   color: ${(props) => props.theme.palette.common.white};
// `;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const DashboardTable = ({ rows }) => (
  <Card mb={6}>
    <CardHeader
      // action={
      //   <IconButton aria-label="settings" size="large">
      //     <MoreVertical />
      //   </IconButton>
      // }
      title="Lastest Leads"
    />
    <Paper>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Customer Mail</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.productName}
                </TableCell>
                <TableCell>{row.customerEmail}</TableCell>
                <TableCell>{TimeFormat(row.creationDateTimeStamp)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </Paper>
  </Card>
);

export default DashboardTable;
