import axios from "../utils/axios";

export const getAll = (filters) => {
  return axios.get(`products/all?${filters}`);
};

export const getAllList = () => {
  return axios.get("products/list");
};

export const create = (data) => {
  return axios.post("products/create", data);
};

export const getOne = (id) => {
  return axios.get(`products/id/${id}`);
};

export const update = (data, id) => {
  return axios.put(`products/update/id/${id}`, data);
};

export const remove = (id) => {
  return axios.delete(`products/delete/id/${id}`);
};

//mapping with thrive

export const getAllProductMap = (filters) => {
  return axios.get(`product-thrive-mapping/all?${filters}`);
};

export const createProductMap = (data) => {
  return axios.post("product-thrive-mapping/create", data);
};

export const updateProductMap = (data) => {
  return axios.put("product-thrive-mapping/update", data);
};

export const getAllInsuranceTypes = () => {
  return axios.get("product-thrive-mapping/insurance-Type");
};

export const deleteProductMapById = (id) => {
  return axios.delete(`product-thrive-mapping/delete/id/${id}`);
};
