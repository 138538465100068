import {} from "./variants";
import variants from "./variants";
import {
  buttonBoxShadow,
  buttonBoxShadowHover,
  cardBoxShadow,
  cardBoxShadowHover,
  faqBoxShadow,
} from "./shadows";

const components = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      contained: {
        borderRadius: "24px",
        fontFamily: "Bree Serif",
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      contained: {
        borderRadius: "24px",
        transition: "all .3s ease-in-out",
        "&:hover": {
          transform: "translateY(-3px)",
        },
      },
      outlined: {
        borderRadius: "24px",
        transition: "all .3s ease-in-out",
        "&:hover": {
          transform: "translateY(-3px)",
        },
      },
    },
    variants: [
      {
        props: { variant: "defy-btn-primary" },
        style: {
          borderRadius: "24px",
          color: ["50"],
          backgroundColor: ["800"],
          transition: "all 0.3s ease-in-out",
          fontFamily: "Bree Serif",
          fontSize: "1rem",
          fontWeight: 400,
          height: "3rem",
          width: "12.3125rem",
          textTransform: "capitalize",
          boxShadow: buttonBoxShadow,
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            backgroundColor: ["600"],
            boxShadow: buttonBoxShadowHover,
            transform: "translateY(-3px)",
          },
        },
      },
      {
        props: { variant: "defy-btn-secondary" },
        style: {
          borderRadius: "24px",
          color: "#FEFEFE",
          backgroundColor: variants[0].sidebar.background,
          transition: "all 0.3s ease-in-out",
          fontFamily: "Bree Serif",
          fontSize: "1rem",
          fontWeight: 400,
          height: "3rem",
          width: "12.3125rem",
          textTransform: "capitalize",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            backgroundColor: variants[0].sidebar.background,
            color: "#FEFEFE",
            boxShadow: buttonBoxShadowHover,
            transform: "translateY(-3px)",
          },
        },
      },
      {
        props: { variant: "defy-btn-view-all" },
        style: {
          borderRadius: "24px",
          color: ["800"],
          backgroundColor: ["50"],
          transition: "all 0.3s ease-in-out",
          fontFamily: "Bree Serif",
          fontSize: "1rem",
          fontWeight: 400,
          width: "12.3125rem",
          height: "3rem",
          boxShadow: buttonBoxShadow,
          textTransform: "capitalize",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            color: ["800"],
            backgroundColor: ["50"],
            boxShadow: buttonBoxShadowHover,
            transform: "translateY(-3px)",
          },
        },
      },
      {
        props: { variant: "defy-btn-get" },
        style: {
          borderRadius: "24px",
          color: "#080809",
          backgroundColor: ["50"],
          transition: "all 0.3s ease-in-out",
          fontFamily: "Bree Serif",
          fontSize: "1rem",
          fontWeight: 400,
          height: "3rem",
          width: "12.3125rem",
          boxShadow: buttonBoxShadow,
          textTransform: "capitalize",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            color: "#080809",
            backgroundColor: ["50"],
            boxShadow: buttonBoxShadowHover,
            transform: "translateY(-3px)",
          },
        },
      },
    ],
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
      },
    },
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        backgroundImage: "none",
        transition: "all .3s ease-in-out",

        "&:hover": {
          boxShadow: cardBoxShadowHover,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px  !important",
        backgroundImage: "none",
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: "300",
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: "64px",
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`,
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "24px",
      },
    },
    variants: [
      {
        props: { variant: "defy-blog-category-chip" },
        style: {
          padding: "5px",
          height: 30,
          lineHeight: "30px",
          background: ["50"],
          color: ["800"],
          borderRadius: "13px",
          border: `0.5px solid ${["800"]}`,
          fontSize: "18px",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "rgba(119, 0, 255, 0.06)",
          },
        },
      },
      {
        props: { variant: "defy-blog-category-chip-active" },
        style: {
          padding: "5px",
          height: 30,
          lineHeight: "30px",
          background: ["500"],
          color: ["900"],
          borderRadius: "13px",
          border: `0.5px solid ${["900"]}`,
          fontSize: "18px",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "rgba(119, 0, 255, 0.06)",
          },
        },
      },
    ],
  },

  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: "13px",
        backgroundColor: [50],
        border: "none",
        boxShadow: cardBoxShadow,
        fontWeight: "bold",
        transition: "all .3s ease-in-out",

        "&:hover": {
          boxShadow: cardBoxShadowHover,
        },

        // "& fieldset": {
        //   border: "none",
        //   borderRadius: "13px",
        // },

        // "&:hover fieldset": {
        //   border: "none",
        // },

        // "&.Mui-focused fieldset": {
        //   border: "none",
        //   borderRadius: "13px",
        // },

        // "& .MuiInputBase-input": {
        //   border: "none",
        //   borderRadius: "13px",
        // },

        // "& .Mui-focused .MuiInputBase-input": {
        //   border: "none",
        // },
      },
    },
  },

  MuiPagination: {
    styleOverrides: {
      outlined: {
        "&.MuiPaginationItem-root": {
          color: variants[0].palette.grey08,
        },
      },
    },
  },
};

export default components;
