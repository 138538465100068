// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

//base url
export const BASE_URL =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? process.env.REACT_APP_PRODURL
    : process.env.REACT_APP_DEVURL;
