import axios from "../utils/axios";

export const getAll = (filters) => {
  return axios.get(`notification/all?${filters}`);
};

export const markRead = (data) => {
  return axios.post("notification/mark-as-read", data);
};

export const unreadCounter = () => {
  return axios.get("notification/unread-counter");
};
