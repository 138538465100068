import axios from "../utils/axios";

//blogs
export const getAllBlogs = (filters) => {
  return axios.get(`blog/all?${filters}`);
};

export const createBlog = (data) => {
  return axios.post("blog/create", data);
};

export const getBlog = (id) => {
  return axios.get(`blog/id/${id}`);
};

export const updateBlog = (data) => {
  return axios.put("blog/update", data);
};

//authors
export const addAuthor = (data, blogId) => {
  return axios.post(`blog/author/create/blogId/${blogId}`, data);
};

export const getAllAuthors = (filters, blogId) => {
  return axios.get(`blog/author/all/blogId/${blogId}?${filters}`);
};

export const getAuthor = (id, blogId) => {
  return axios.get(`blog/author/blogId/${blogId}/id/${id}`);
};

export const updateAuthor = (data, blogId) => {
  return axios.put(`blog/author/update/blogId/${blogId}`, data);
};

export const deleteAuthor = (id, userAuthId, blogId) => {
  return axios.delete(`blog/author/delete/blogId/${blogId}/${id}`, {
    params: { id, userAuthId },
  });
};

//categories
export const getAllCategories = (filters, blogId) => {
  return axios.get(`blog/category/all/blogId/${blogId}?${filters}`);
};

export const getCategory = (id, blogId) => {
  return axios.get(`blog/category/blogId/${blogId}/id/${id}`);
};

export const createCategory = (data, blogId) => {
  return axios.post(`blog/category/create/blogId/${blogId}`, data);
};

export const updateCategory = (data, blogId) => {
  return axios.put(`blog/category/update/blogId/${blogId}`, data);
};

export const deleteCategory = (id, userAuthId, blogId) => {
  return axios.delete(`blog/category/delete/blogId/${blogId}/${id}`, {
    params: { id, userAuthId },
  });
};

//posts
export const getAllPosts = (filters, blogId) => {
  return axios.get(`blog/post/all/blogId/${blogId}?${filters}`);
};

export const createPost = (data, blogId) => {
  return axios.post(`blog/post/create/blogId/${blogId}`, data);
};

export const getPost = (id, blogId) => {
  return axios.get(`blog/post/blogId/${blogId}/id/${id}`);
};

export const updatePost = (data, blogId) => {
  return axios.put(`blog/post/update/blogId/${blogId}`, data);
};

export const deletePost = (id, userAuthId, blogId) => {
  return axios.delete(`blog/post/delete/blogId/${blogId}/${id}`, {
    params: {
      id,
      blogId,
      userAuthId,
    },
  });
};

//images
export const uploadImage = (data, blogId) => {
  return axios.post(`blog/file/upload/blogId/${blogId}`, data);
};

export const updateImage = (data, blogId) => {
  return axios.put(`blog/file/update/blogId/${blogId}`, data);
};

export const uploadBlogImage = (data) => {
  return axios.post("blog/file/blog/upload", data);
};
