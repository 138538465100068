import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { store } from "../redux/store";
import { logout } from "../redux/slices/auth";
import Page404 from "../pages/auth/Page404";

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const path = useLocation().pathname;

  const tokenExpiration = useSelector((state) => state.auth.tokenExpiration);
  const isAuthenticated = useSelector((state) => state.auth.isAuthorize);
  const privileges = useSelector((state) => state.privileges.privileges);

  //privileges block
  const checkPrivileges = () => {
    //roles
    if (path.includes("/roles/add") && !privileges.includes("ROLE_CREATE")) {
      return false;
    } else if (
      path.includes("/roles/edit") &&
      !privileges.includes("ROLE_UPDATE")
    )
      return false;
    else if (path === "/roles" && !privileges.includes("ROLE_READ"))
      return false;
    //blogs
    else if (
      (path.includes("blogs/list") ||
        path.includes("blogs/authors") ||
        path.includes("/blogs/categories") ||
        path.includes("/blogs/posts")) &&
      !privileges.includes("BLOG_READ")
    )
      return false;
    else if (
      (path.includes("/blogs/list/add") ||
        path.includes("/authors/add") ||
        path.includes("/categories/add") ||
        path.includes("/posts/add")) &&
      !privileges.includes("BLOG_WRITE")
    )
      return false;
    else if (
      (path.includes("/blogs/list/add") ||
        path.includes("/authors/add") ||
        path.includes("/categories/add") ||
        path.includes("/posts/add")) &&
      !privileges.includes("BLOG_WRITE")
    )
      return false;
    //cms
    else if (path === "/cms/pages" && !privileges.includes("PAGE_READ"))
      return false;
    else if (
      path.includes("/cms/pages/add") &&
      !privileges.includes("PAGE_WRITE")
    )
      return false;
    else if (
      path.includes("/cms/pages/edit") &&
      !privileges.includes("PAGE_UPDATE")
    )
      return false;
    //cms section
    else if (
      path.includes("/sections/add") &&
      !privileges.includes("SECTION_WRITE")
    )
      return false;
    else if (
      path.includes("/sections/edit/") &&
      !privileges.includes("SECTION_UPDATE")
    )
      return false;
    else if (
      path.includes("/cms/pages") &&
      path.includes("/sections") &&
      !privileges.includes("SECTION_READ")
    )
      return false;
    //industry
    else if (
      (path.includes("/industry/add") || path.includes("profession/add")) &&
      !privileges.includes("INDUSTRY_WRITE")
    )
      return false;
    else if (
      (path.includes("/industry/edit") || path.includes("/profession/edit/")) &&
      !privileges.includes("INDUSTRY_UPDATE")
    )
      return false;
    //product
    else if (
      path.includes("/products/add") &&
      !privileges.includes("PRODUCT_WRITE")
    )
      return false;
    else if (
      path.includes("/products/edit") &&
      !privileges.includes("PRODUCT_UPDATE")
    )
      return false;
    //carrier
    else if (path.includes("/carriers") && !privileges.includes("CARRIER_READ"))
      return false;
    else if (
      path.includes("/carriers/add") &&
      !privileges.includes("CARRIER_WRITE")
    )
      return false;
    else if (
      path.includes("/carriers/edit/") &&
      !privileges.includes("CARRIER_UPDATE")
    )
      return false;
    //testimonial
    else if (
      path.includes("/testimonials") &&
      !privileges.includes("TESTIMONIAL_READ")
    )
      return false;
    else if (
      path.includes("/testimonials/add") &&
      !privileges.includes("TESTIMONIAL_WRITE")
    )
      return false;
    else if (
      path.includes("/testimonials/edit") &&
      !privileges.includes("TESTIMONIAL_UPDATE")
    )
      return false;
    //partner
    else if (
      (path.includes("/partners/prospect-partners") ||
        path.includes("/partners/images-list")) &&
      !privileges.includes("PARTNER_IMAGE_READ")
    )
      return false;
    else if (
      path.includes("/partners/images-list/add") &&
      !privileges.includes("PARTNER_IMAGE_WRITE")
    )
      return false;
    else if (
      path.includes("partners/images-list/edit/") &&
      !privileges.includes("PARTNER_IMAGE_UPDATE")
    )
      return false;
    //claim
    else if (path.includes("/claims") && !privileges.includes("CLAIM_READ"))
      return false;
    else {
      return true;
    }
  };

  const isTokenExpired = () => {
    const currentTime = new Date().toISOString();
    if (currentTime > tokenExpiration) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isTokenExpired()) {
      store.dispatch(logout());
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate("/auth/sign-in");
    }
  }, [isAuthenticated]);

  if (isTokenExpired()) {
    return null;
  } else {
    if (checkPrivileges()) {
      return <>{children}</>;
    } else {
      return <Page404 />;
    }
  }
}

export default AuthProvider;
