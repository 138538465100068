import React, { useState } from "react";
import {
  Box,
  Modal,
  IconButton,
  Typography,
  Grid,
  Chip,
  Button,
  Divider,
  Stack,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { updateCertificate } from "../../Apis/certificateApi";
import TimeFormat from "../../utils/timeFormat";
import variants from "../../theme/variants";
import { useMessage } from "../../hooks/useMessage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  maxHeight: "95vh",
  overflowY: "scroll",
  boxShadow: 24,
  p: 10,
};

export default function UpdateCertificate({ data, getCertificates }) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(data.status ? data.status : "");
  const [remarks, setRemarks] = useState(data.remark ? data.remark : "");

  const message = useMessage();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "remarks":
        setRemarks(value);
        break;
      case "status":
        setStatus(value);
        break;

      default:
        break;
    }
  };
  const updateCertificateRequest = () => {
    let updatedData = {
      id: data.id,
      urgent: data.urgent,
      businessName: data.businessName,
      businessEmail: data.businessEmail,
      phoneNumber: data.phoneNumber,
      policyNumber: data.policyNumber,
      certificateHolder: data.certificateHolder,
      additionalInsuredRequired: data.additionalInsuredRequired,
      anySpecialDescriptionRequired: data.anySpecialDescriptionRequired,
      status,
      remark: remarks,
    };
    updateCertificate(updatedData)
      .then((response) => {
        message("Certificate Updated", "success");
        handleClose();
        getCertificates();
      })
      .catch((err) => message(err.response.data.message, "error"));
  };

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <Edit />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h2" align="center">
              {`Update Certificate Request`}
            </Typography>
          </Box>

          <Typography
            variant="body1"
            align="center"
            sx={{
              pb: 3,
              borderBottom: `1px solid ${variants[0].palette.primary.main}`,
            }}
          >
            Requested at {TimeFormat(data.creationDateTimeStamp)}
          </Typography>

          <Box sx={{ mt: 1, mb: 3 }}>
            <Grid container mt={4} spacing={4} mb={10}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  margin="normal"
                  id="remarks"
                  required
                  name="remarks"
                  label="Remarks"
                  placeholder="Please write here"
                  variant="outlined"
                  rows={3}
                  value={remarks}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-status">Status</InputLabel>
                  <Select
                    name="status"
                    labelId="select-status"
                    id="status"
                    value={status}
                    margin="normal"
                    label="Status"
                    onChange={handleChange}
                  >
                    <MenuItem value="NEW">New</MenuItem>
                    <MenuItem value="IN_REVIEW">In Review</MenuItem>
                    <MenuItem value="PROVIDED">Provided</MenuItem>
                    <MenuItem value="IGNORED">Ignored</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Divider />
          <Stack mt={3} direction="row" justifyContent={"end"} gap={2}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleClose()}
            >
              Close
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={!remarks || !status}
              onClick={() => updateCertificateRequest()}
            >
              Update
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
