import React from "react";
import { Typography } from "@mui/material";

export default function NotFound() {
  return (
    <Typography align="center" variant="h4" sx={{ my: 2 }}>
      Content Not Found
    </Typography>
  );
}
