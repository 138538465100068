const buttonBoxShadow = () => {
  return {
    boxShadow: "-1px 4px 27px -8px rgba(0, 0, 0, 0.36)",
  };
};

const buttonBoxShadowHover = () => {
  return {
    boxShadow: "-2px 5px 30px -10px rgba(0, 0, 0, 0.36)",
  };
};

const cardBoxShadow = () => {
  return {
    boxShadow: "-1px 4px 27px -8px rgba(0, 0, 0, 0.36)",
  };
};

const cardBoxShadowHover = () => {
  return {
    boxShadow: "-2px 5px 30px -10px rgba(0, 0, 0, 0.36)",
  };
};

const faqBoxShadow = () => {
  return {
    boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.14)",
  };
};

export {
  buttonBoxShadow,
  buttonBoxShadowHover,
  cardBoxShadow,
  cardBoxShadowHover,
  faqBoxShadow,
};
