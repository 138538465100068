import axios from "../utils/axios";

export const getAllCertificate = (filters) => {
  return axios.get(`insurance/certificate/get/all?${filters}`);
};

export const getCertificateById = () => {
  return axios.get(`insurance/certificate/get/${id}`);
};

export const updateCertificate = (data) => {
  return axios.put(`insurance/certificate/update`, data);
};

export const deleteCertificate = (id) => {
  return axios.delete(`insurance/certificate/delete/${id}`);
};
