import axios from "../utils/axios";

export const getAll = (filters) => {
  return axios.get(`contact-us/all?${filters}`);
};

export const getAllContactsCount = () => {
  return axios.get("contact-us/count-leads");
};

export const contacted = (id) => {
  return axios.put(`contact-us/contacted/id/${id}`);
};

export const remove = (id) => {
  return axios.delete(`contact-us/delete/id/${id}`);
};
