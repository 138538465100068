import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  industryId: null,
  industryName: null,
};

export const questionIndustry = createSlice({
  name: "questionIndustry",
  initialState,
  reducers: {
    setQuestionIndustry: (state, action) => {
      state.industryId = action.payload.industryId;
      state.industryName = action.payload.industryName;
    },
  },
});

export const { setQuestionIndustry } = questionIndustry.actions;

export default questionIndustry.reducer;
