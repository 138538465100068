import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { Bell, Tag } from "react-feather";

import { getAll, unreadCounter, markRead } from "../../Apis/notificationApi";

import params from "../../utils/params";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({ title, description, Icon, redirect }) {
  return (
    <ListItem divider component={Link} to={redirect}>
      <ListItemAvatar>
        <Avatar>
          <SvgIcon fontSize="small">
            <Icon />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={description}
      />
    </ListItem>
  );
}

function NavbarNotificationsDropdown() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [counter, setCounter] = useState(0);
  const [read, setRead] = useState(false);

  const handleRedirect = (type) => {
    if (type === "AGENT_LEAD") {
      return "/agents/prospect-agents";
    } else if (type === "CONTACT_US_RESPONSE") {
      return "/contacts";
    } else if (type === "PARTNER_LEAD") {
      return "/partners/prospect-partners";
    } else if (type === "CLAIM_CENTER") {
      return "/claims";
    } else return "/notifications";
  };

  const readAllNotifications = () => {
    markRead().then((response) => {
      getUnread();
      // getNotifications();
      setOpen(false);
      return navigate("/notifications");
    });
  };

  const getNotifications = () => {
    getAll(
      params({ isRead: "false", sortBy: "creationDate", ascOrDesc: "desc" })
    ).then((response) => {
      setNotifications(response.data.payload.content);
    });
  };

  const getUnread = () => {
    unreadCounter().then((response) => setCounter(response.data.payload));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getNotifications();
    getUnread();
  }, []);

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={counter}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {counter !== 0 ? counter : "No"} New Notifications
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            {/* <Notification
              title="Update complete"
              description="Restart server to complete update."
              Icon={Server}
            /> */}
            {notifications.length < 1 ? (
              <Typography align="center" sx={{ my: 2 }}>
                No unread notifications
              </Typography>
            ) : (
              notifications.map((notification) => (
                <Notification
                  key={notification.id}
                  title={notification.title}
                  description={notification.message}
                  Icon={Tag}
                  redirect={handleRedirect(notification.notificationType)}
                />
              ))
            )}

            {/* <Notification
              title="Lorem ipsum"
              description="Aliquam ex eros, imperdiet vulputate hendrerit et"
              Icon={Bell}
            />
            <Notification
              title="New login"
              description="Login from 192.186.1.1."
              Icon={Home}
            /> */}
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" onClick={() => readAllNotifications()}>
              Show all notifications
            </Button>
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
