import axios from "../utils/axios";

export const getAll = (filters) => {
  return axios.get(`industry/all?${filters}`);
};

export const getProfessionalAll = (industryId) => {
  if (industryId) {
    return axios.get(`industry/all/dropdown?id=${industryId}`);
  } else {
    return axios.get(`industry/all/dropdown`);
  }
};

export const getAllIndustryDropdown = () => {
  return axios.get("industry/all/faq/dropdown");
};

export const create = (data) => {
  return axios.post("industry/create", data);
};

export const getOne = (id) => {
  return axios.get(`industry/id/${id}`);
};

export const update = (data, id) => {
  return axios.put(`industry/update/id/${id}`, data);
};

export const remove = (id) => {
  return axios.delete(`industry/delete/id/${id}`);
};
