import axios from "../utils/axios";

export const getAll = (filters) => {
  return axios.get(`agents/defy-agents?${filters}`);
};

export const getAgentsCount = () => {
  return axios.get("agents/defy-agents/count");
};

export const prospectAll = (filters) => {
  return axios.get(`become-agent/all?${filters}`);
};

export const contacted = (id) => {
  return axios.put(`become-agent/contacted/id/${id}`);
};

export const deleteProspect = (id) => {
  return axios.delete(`become-agent/delete/id/${id}`);
};
