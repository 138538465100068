import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField,
} from "@mui/material";

export default function SortFilter({
  handleSort,
  sortValues,
  sortBy,
  ascOrDesc,
  ascDescValues,
}) {
  return (
    <Box component="div" display="flex">
      <FormControl sx={{ width: 200 }}>
        <InputLabel id="select-active">Sort By</InputLabel>
        <Select
          labelId="select-active"
          value={sortBy}
          fullWidth
          label="Active"
          name="active"
          onChange={(e) => handleSort(e, "sort")}
          size="small"
        >
          {sortValues.map((item) => (
            <MenuItem key={item.label} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box ml={2} width={80}>
        <TextField
          value={ascOrDesc}
          fullWidth
          select
          label="Asc Or Desc"
          name="active"
          onChange={(e) => handleSort(e, "ascOrDesc")}
          size="small"
        >
          <MenuItem value={ascDescValues?.asc || "asc"}>Asc</MenuItem>
          <MenuItem value={ascDescValues?.desc || "desc"}>Desc</MenuItem>
        </TextField>
      </Box>
    </Box>
  );
}
