import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import AddFaqForm from "../../forms/Faq/AddFaqForm";
import { useMessage } from "../../../hooks/useMessage";

import { getOne, add, update } from "../../../Apis/faqApi";
import {
  getAllIndustryDropdown,
  getOne as getIndustry,
} from "../../../Apis/industryApi";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useDispatch } from "react-redux";
import {
  createSpecificQuestion,
  getSpecificQuestiongetById,
  updateSpecificQuestion,
} from "../../../Apis/specificQuestionApi";
import { setQuestionIndustry } from "../../../redux/slices/questionIndustry";
import AddSpecificQuestionForm from "../../forms/SpecificQuestion/AddSpecificQuestionForm";
import { uploadFile } from "../../../Apis/fileApi";
import ImageUpload from "../../components/imageUploader";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function AddSpecificQuestion() {
  const navigate = useNavigate();
  const message = useMessage();
  const params = useParams();
  const dispatch = useDispatch();
  const industryId = params?.industryId;
  const questionId = params?.id;
  const [question, setQuestion] = useState({});
  const [industries, setIndustries] = useState([]);
  const [image, setImage] = useState("/placeholder-image.jpg");
  const [imageId, setImageId] = useState(null);

  const fetchQuestion = () => {
    if (questionId) {
      getSpecificQuestiongetById(questionId)
        .then((response) => {
          setQuestion(response.data.payload);
          if (response.data.payload.file) {
            setImageId(response.data.payload.file.id);
            setImage(response.data.payload.file.fileUrl);
          }
        })
        .catch((err) => console.log("question fetch error", err));
    }
  };

  const getIndustries = () => {
    getAllIndustryDropdown()
      .then((response) => setIndustries(response.data.payload))
      .catch((err) => console.error("industry failed", err));
  };

  const fetchIndustry = (id) => {
    if (id) {
      getIndustry(id).then((response) => {
        dispatch(
          setQuestionIndustry({
            industryId: id,
            industryName: response.data.payload.name,
          })
        );
      });
    }
  };

  const submitImage = (values) => {
    values.append("fileType", values.get("file").type);
    uploadFile(values)
      .then((response) => {
        // console.log("image uploaded", response);
        setImage(response.data.payload.fileUrl);
        setImageId(response.data.payload.id);
        message("Industry image uploaded", "success");
      })
      .catch((err) => {
        message("Image upload error", "error");
        console.log("image upload failed", err.response);
      });
  };

  const submitQuestion = (values) => {
    // console.log("the values", values);
    const data = JSON.stringify({
      ...values,
      id: questionId ? questionId : null,
      fileId: imageId,
    });

    fetchIndustry(values.industryId);

    if (questionId) {
      updateSpecificQuestion(data)
        .then((response) => {
          message("Specific Question updated", "success");
          return navigate("/specific-question");
        })
        .catch((err) => console.log("update error", err));
    } else {
      createSpecificQuestion(data)
        .then((response) => {
          message("Specific Question Created", "success");
          // console.log("faq response", response);
          return navigate("/specific-question");
        })
        .catch((err) => console.log("author error", err));
    }
  };

  useEffect(() => {
    fetchQuestion();
    getIndustries();
  }, []);

  return (
    <React.Fragment>
      <Helmet title={`${questionId ? "Edit" : "Add"} Specific Question`} />
      <Box display="flex" flexDirection="column">
        <Typography variant="h3" gutterBottom display="inline">
          {questionId ? "Edit" : "Add"} Specific Question
        </Typography>
        {/* <Typography
          color="secondary"
          variant="h5"
          gutterBottom
          display="inline"
        >
          Product: {product?.name}
        </Typography> */}
      </Box>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/specific-questions">
          Specific Questions
        </Link>
        <Typography>{questionId ? "Edit" : "Add"}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <ImageUpload
                    image={image}
                    imageType="INDUSTRY_IMAGE"
                    submitImage={submitImage}
                    label="Upload Image"
                  />
                </Grid>
              </Grid>
              <AddSpecificQuestionForm
                navigate={navigate}
                question={question}
                industries={industries}
                industryId={industryId}
                onSubmit={submitQuestion}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AddSpecificQuestion;
