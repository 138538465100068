import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productId: null,
  productName: null,
};

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    setFaqProduct: (state, action) => {
      state.productId = action.payload.productId;
      state.productName = action.payload.productName;
    },
  },
});

export const { setFaqProduct } = faqSlice.actions;

export default faqSlice.reducer;
