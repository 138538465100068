import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import AuthorForm from "../../forms/Blogs/AuthorForm";
import {
  uploadImage,
  updateImage,
  addAuthor,
  getAuthor,
  updateAuthor,
} from "../../../Apis/blogApi";
import { useMessage } from "../../../hooks/useMessage";
import { useSelector } from "react-redux";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import ImageUpload from "../../components/imageUploader";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function AddAuthor() {
  const navigate = useNavigate();
  const message = useMessage();
  const params = useParams();
  const blogId = params?.blogId;
  const authorId = params?.id;
  const user = useSelector((state) => state.auth.user);
  const [image, setImage] = useState("/user-placeholder.png");
  const [imageId, setImageId] = useState(null);
  const [author, setAuthor] = useState({});

  const fetchAuthor = () => {
    if (authorId) {
      getAuthor(authorId, blogId)
        .then((response) => {
          setAuthor(response.data.payload);
          setImage(response.data.payload.image.fileUrl);
          setImageId(response.data.payload.image.id);
        })
        .catch((err) => console.log("author fetch error", err));
    }
  };

  const submitImage = (values) => {
    values.append("userAuthId", user.id);
    if (authorId && imageId) {
      values.append("fileId", imageId);
      updateImage(values, blogId)
        .then((response) => {
          console.log("image update response", response);
          setImage(response.data.payload.body.payload.fileUrl);
          setImageId(response.data.payload.body.payload.id);
          message("Author image updated", "success");
        })
        .catch((err) => {
          message("Image update failed", "error");
        });
    } else {
      uploadImage(values, blogId)
        .then((response) => {
          console.log("image uploaded", response);
          setImage(response.data.payload.fileUrl);
          setImageId(response.data.payload.id);
          message("Author image uploaded", "success");
        })
        .catch((err) => {
          message("Image upload error", "error");
          console.log("image upload failed", err.response);
        });
    }
  };

  const submitAuthor = (values) => {
    let data = authorId
      ? JSON.stringify({
          ...values,
          imageFileId: imageId,
          userAuthId: user.id,
          authorId: authorId,
        })
      : JSON.stringify({
          ...values,
          imageFileId: imageId,
          userAuthId: user.id,
        });

    if (authorId) {
      updateAuthor(data, blogId)
        .then((response) => {
          message("Author updated", "success");
          return navigate("/blogs/authors");
        })
        .catch((err) => console.log("update error", err));
    } else {
      addAuthor(data, blogId)
        .then((response) => {
          message("Author Created", "success");
          console.log("user response", response);
          return navigate("/blogs/authors");
        })
        .catch((err) => console.log("author error", err));
    }
  };

  useEffect(() => {
    fetchAuthor();
  }, []);

  return (
    <React.Fragment>
      <Helmet title={`${author?.id ? "Edit" : "Add"} Author`} />
      <Typography variant="h3" gutterBottom display="inline">
        {author?.id ? "Edit" : "Add"} Author
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/blogs/authors">
          Authors
        </Link>
        <Typography>{author?.id ? "Edit" : "Add"}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <ImageUpload
                image={image}
                imageType="AUTHOR_IMAGE"
                submitImage={submitImage}
              />
              <AuthorForm author={author} onSubmit={submitAuthor} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AddAuthor;
