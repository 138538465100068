import axios from "../utils/axios";

export const getAll = (filters) => {
  return axios.get(`claim-center/all?${filters}`);
};

export const getClaimsCount = () => {
  return axios.get("claim-center/claim-count");
};

export const contacted = (id) => {
  return axios.put(`claim-center/contacted/id/${id}`);
};

export const remove = (id) => {
  return axios.delete(`claim-center/delete/id/${id}`);
};
