import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAll, remove } from "../../../Apis/faqApi";
import { useMessage } from "../../../hooks/useMessage";

import FaqButton from "../../components/FaqButton";
import {
  CardContent,
  Grid,
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  deleteSpecificQuestion,
  getAllSpecificQuestions,
} from "../../../Apis/specificQuestionApi";
import { getAllIndustryDropdown } from "../../../Apis/industryApi";
import QuestionButton from "../../components/QuestionButton";
import SearchFilter from "../../components/SearchFilter";
import NotFound from "../../components/NotFound";
import QuestionTable from "../../components/tables/questionTable";
import ContentLoader from "../../components/contentLoader";
import params from "../../../utils/params";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function SpecificQuestion() {
  const [questions, setQuestions] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [filterBy, setFilterBy] = useState("question");
  const [searchBy, setSearchBy] = useState({
    question: "",
  });
  const navigate = useNavigate();
  const message = useMessage();
  const industryId = useSelector((state) => state.questionIndustry.industryId);

  const filters = [
    {
      value: "question",
      label: "Question",
    },
  ];

  const getQuestions = () => {
    if (Object.keys(searchBy).length > 0) {
      setLoading(true);
      getAllSpecificQuestions(
        params({
          pageNo: currentPage,
          pageSize: rowsPerPage,
          industryId: industryId,
          question: searchBy.question,
          sortBy: "creationDate",
          ascOrDesc: "desc",
        })
      )
        .then((response) => {
          setRowsPerPage(response.data.payload.size);
          setCount(response.data.payload.totalElements);
          setQuestions(response.data.payload.content);
          setLoading(false);
        })
        .catch((err) => {
          console.log("question fetch error", err);
          setLoading(false);
        });
    }
  };

  const getIndustries = () => {
    getAllIndustryDropdown()
      .then((response) => {
        setIndustries(response.data.payload);
      })
      .catch((err) => console.error("industry failed", err));
  };

  const showDeleteModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };
  const hideDeleteModal = () => setShowDelete(false);

  const submitDeleteQuestion = () => {
    deleteSpecificQuestion(deleteId)
      .then((response) => {
        setShowDelete(false);
        message("Specific Question deleted", "success");
        setDeleteId(null);
        getQuestions();
      })
      .catch((err) => message("Specific Question delete failed", "error"));
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  //search
  const handleSearch = (e, type) => {
    const value = e.target.value;
    setSearchBy({ ...searchBy, [type]: value });
  };

  const handleFilter = (e) => {
    setFilterBy(e.target.value);
    setSearchBy({});
  };

  useEffect(() => {
    getIndustries();
  }, []);

  useEffect(() => {
    getQuestions();
  }, [currentPage, rowsPerPage, industryId, searchBy]);
  return (
    <React.Fragment>
      <Helmet title="Specific Questions | List " />
      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this question?</DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={submitDeleteQuestion}
          >
            Delete
          </Button>
          <Button onClick={hideDeleteModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Specific Questions
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Specific Question</Typography>
            <Typography>List</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <QuestionButton
            data={industries}
            btnText="Select Industry Filter"
            variant="outlined"
            title="name"
          />
          <Button
            onClick={() => navigate(`/specific-question/${industryId}/add`)}
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
            // disabled={!productId}
          >
            <AddIcon />
            Add Specific Question
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <SearchFilter
                filters={filters}
                filterBy={filterBy}
                handleFilter={handleFilter}
                handleSearch={handleSearch}
              />
              {loading ? (
                <ContentLoader />
              ) : (
                <>
                  {questions.length < 1 ? (
                    <NotFound />
                  ) : (
                    <>
                      <QuestionTable
                        deleteQuestion={showDeleteModal}
                        rows={questions}
                      />
                      <TablePagination
                        rowsPerPageOptions={[20, 50, 100]}
                        count={count}
                        page={currentPage}
                        rowsPerPage={rowsPerPage}
                        onPageChange={(_, page) => handleChangePage(page)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        component="div"
                      />
                    </>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SpecificQuestion;
