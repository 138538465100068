import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Avatar,
} from "@mui/material";

import ReactHtmlParser from "react-html-parser";

import { useNavigate } from "react-router-dom";

import TimeFormat from "../../../utils/timeFormat";

import { Edit, Delete } from "@mui/icons-material";

export default function QuestionTable(props) {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Question</TableCell>
            <TableCell align="left">Answer</TableCell>
            {/* {/* <TableCell align="left">Created By</TableCell> */}
            <TableCell align="left">CTA Button</TableCell>
            <TableCell align="left">Button Link</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{row.question}</TableCell>
              <TableCell align="left">
                {row.answer && ReactHtmlParser(row.answer)}
              </TableCell>
              <TableCell align="left">{row.cta}</TableCell>
              <TableCell align="left">{row.cta_link}</TableCell>
              {/* <TableCell align="left">
                {TimeFormat(row.creationDateTimeStamp)}
              </TableCell> */}
              <TableCell align="left">
                <div>
                  <IconButton
                    onClick={() => {
                      navigate(
                        `/specific-question/${row.industryId}/edit/${row.id}`
                      );
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => props.deleteQuestion(row.id)}>
                    <Delete />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
