import React, { useRef } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import ReactQuill from "react-quill";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const validationSchema = Yup.object().shape({
  question: Yup.string().required("Question is required"),
  answer: Yup.string().required("Answer is required"),
  alignment: Yup.string().required("Image Alignment is required"),
  cta: Yup.string().required("CTA Button is required"),
  cta_link: Yup.string().required("Button Link is required"),
  industryId: Yup.string().required("Industry is required"),
});

function AddSpecificQuestionForm(props) {
  const { question, industries, industryId } = props;

  const quillRef = useRef(null);

  const onChangeQuill = () => {
    const length = quillRef.current?.unprivilegedEditor.getLength();
    if (length < 2) return false;
    else return true;
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await props.onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const initialValues =
    Object.keys(question).length !== 0
      ? {
          question: question.question,
          answer: question.answer,
          alignment: question.alignment,
          cta: question.cta,
          cta_link: question.cta_link,
          industryId: question.industryId,
        }
      : {
          question: "",
          answer: "",
          alignment: "RIGHT",
          cta: "",
          cta_link: "",
          industryId: industryId !== "null" && industryId ? industryId : "",
        };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        setFieldValue,
      }) => (
        <Card mb={6}>
          <CardContent>
            {/* {status && status.sent && (
              <Alert severity="success" my={3}>
                [DEMO] Your data has been submitted successfully!
              </Alert>
            )} */}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="alignment"
                      label="Image Alignment"
                      value={values.alignment}
                      error={Boolean(touched.alignment && errors.alignment)}
                      fullWidth
                      helperText={touched.alignment && errors.alignment}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      select
                    >
                      <MenuItem value="RIGHT">Right</MenuItem>
                      <MenuItem value="LEFT">Left</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="industryId"
                      label="Select Industry"
                      value={values.industryId}
                      error={Boolean(touched.industryId && errors.industryId)}
                      fullWidth
                      helperText={touched.industryId && errors.industryId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      select
                    >
                      {industries &&
                        industries.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="cta"
                      label="CTA Button"
                      value={values.cta}
                      error={Boolean(touched.cta && errors.cta)}
                      fullWidth
                      helperText={touched.cta && errors.cta}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="cta_link"
                      label="Button Link"
                      value={values.cta_link}
                      error={Boolean(touched.cta_link && errors.cta_link)}
                      fullWidth
                      helperText={touched.cta_link && errors.cta_link}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <TextField
                  name="question"
                  label="Question"
                  value={values.question}
                  error={Boolean(touched.question && errors.question)}
                  fullWidth
                  helperText={touched.question && errors.question}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />

                <Box sx={{ ".ql-container": { height: 200 }, my: 2 }}>
                  <Typography sx={{ my: 2 }} variant="h5">
                    Answer
                  </Typography>
                  <ReactQuill
                    theme="snow"
                    ref={quillRef}
                    name="answer"
                    style={{
                      height: "100%",
                      border: `${
                        Boolean(touched.answer && errors.answer)
                          ? "1px solid red"
                          : ""
                      }`,
                    }}
                    placeholder="Type here"
                    value={values.answer}
                    onChange={(value) => {
                      onChangeQuill()
                        ? setFieldValue("answer", value)
                        : setFieldValue("answer", "");
                    }}
                  />
                  {Boolean(touched.answer && errors.answer) && (
                    <Typography color="error" variant="caption">
                      Answer is required
                    </Typography>
                  )}
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  disabled={isSubmitting}
                >
                  Save changes
                </Button>
                <Button
                  mt={3}
                  onClick={() => props.navigate("/specific-question")}
                >
                  Cancel
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default AddSpecificQuestionForm;
