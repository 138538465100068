import axios from "../utils/axios";

export const uploadFile = (data) => {
  return axios.post("file/upload", data);
};

export const getFile = (id) => {
  return axios.get(`file/id/${id}`, {
    responseType: "blob",
  });
};
