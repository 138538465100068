import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMessage } from "../../../hooks/useMessage";

import {
  CardContent,
  Grid,
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TablePagination,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";

import { spacing } from "@mui/system";
import params from "../../../utils/params";

import ContentLoader from "../../components/contentLoader";
import NotFound from "../../components/NotFound";
import SearchFilter from "../../components/SearchFilter";
import SortFilter from "../../components/SortFilter";
import {
  deleteCertificate,
  getAllCertificate,
} from "../../../Apis/certificateApi";
import CertificateTable from "../../components/tables/certificateTable";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Certificates() {
  const [certificates, setCertificates] = useState([]);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filterBy, setFilterBy] = useState("businessName");
  const [searchBy, setSearchBy] = useState({
    businessName: "",
    businessEmail: "",
    phoneNumber: "",
    policyNumber: "",
    remark: "",
    status: "",
  });
  const [sortBy, setSortBy] = useState("creationDate");
  const [ascOrDesc, setAscOrDesc] = useState("desc");
  const navigate = useNavigate();
  const message = useMessage();
  const privileges = useSelector((state) => state.privileges.privileges);

  const filters = [
    {
      value: "businessName",
      label: "Business Name",
    },
    {
      value: "businessEmail",
      label: "Business Email",
    },
    {
      value: "phoneNumber",
      label: "Phone Number",
    },
    {
      value: "policyNumber",
      label: "Policy Number",
    },
    {
      value: "remark",
      label: "Remarks",
    },
    {
      value: "status",
      label: "Status",
    },
  ];

  const getCertificates = () => {
    if (Object.keys(searchBy).length > 0) {
      setLoading(true);
      getAllCertificate(
        params({
          pageNo: currentPage,
          pageSize: rowsPerPage,
          businessName: searchBy.businessName ? searchBy.businessName : null,
          businessEmail: searchBy.businessEmail ? searchBy.businessEmail : null,
          phoneNumber: searchBy.phoneNumber ? searchBy.phoneNumber : null,
          policyNumber: searchBy.policyNumber ? searchBy.policyNumber : null,
          remark: searchBy.remark ? searchBy.remark : null,
          status: searchBy.status ? searchBy.status : null,
          ascOrDesc: ascOrDesc,
          sortBy: sortBy,
        })
      )
        .then((response) => {
          console.log("get certificate", response);
          setCurrentPage(response.data.payload.pageable.pageNumber);
          setRowsPerPage(response.data.payload.pageable.pageSize);
          setCount(response.data.payload.totalElements);
          setCertificates(response.data.payload.content);
          setLoading(false);
        })
        .catch((err) => {
          console.log("claim fetch error", err);
          setLoading(false);
        });
    }
  };

  const showDeleteModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };
  const hideDeleteModal = () => setShowDelete(false);

  const submitDeleteCertificate = () => {
    deleteCertificate(deleteId)
      .then((response) => {
        message("Certificate Deleted", "success");
        hideDeleteModal();
        getCertificates();
      })
      .catch((err) => message(err.response.data.message, "error"));
  };

  //   const submitContacted = (id) => {
  //     contacted(id)
  //       .then((response) => {
  //         message("Status changed to contacted", "success");
  //         getClaims();
  //       })
  //       .catch((err) => message("Error occurred", "error"));
  //   };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  //search
  const handleSearch = (e, type) => {
    const value = e.target.value;
    setSearchBy({ ...searchBy, [type]: value });
  };

  const handleFilter = (e) => {
    setFilterBy(e.target.value);
    setSearchBy({});
  };

  //sorting
  const sortValues = [
    { label: "Business Name", value: "businessName" },
    {
      label: "Business Email",
      value: "businessEmail",
    },
    { label: "Phone Number", value: "phoneNumber" },
    {
      label: "Policy Number",
      value: "policyNumber",
    },
    {
      label: "Remarks",
      value: "remark",
    },
    { label: "Status", value: "status" },
    { label: "Created At", value: "creationDate" },
  ];

  const handleSort = (e, type) => {
    const value = e.target.value;
    if (type === "ascOrDesc") {
      setAscOrDesc(value);
    } else {
      setSortBy(value);
    }
  };

  useEffect(
    () => getCertificates(),
    [currentPage, rowsPerPage, searchBy, sortBy, ascOrDesc]
  );
  return (
    <React.Fragment>
      <Helmet title="Certificates | List " />
      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this certificate?</DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={submitDeleteCertificate}
          >
            Delete
          </Button>
          <Button onClick={hideDeleteModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Certificate Requests
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Certificate Requests</Typography>
            <Typography>List</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <SearchFilter
                  filters={filters}
                  filterBy={filterBy}
                  handleFilter={handleFilter}
                  handleSearch={handleSearch}
                  certificateStatusOptions={[
                    "NEW",
                    "IN_REVIEW",
                    "IGNORED",
                    "PROVIDED",
                  ]}
                />
                <SortFilter
                  sortBy={sortBy}
                  ascOrDesc={ascOrDesc}
                  sortValues={sortValues}
                  handleSort={handleSort}
                />
              </Box>
              {loading ? (
                <ContentLoader />
              ) : (
                <>
                  {certificates.length < 1 ? (
                    <NotFound />
                  ) : (
                    <>
                      <CertificateTable
                        deleteClaim={showDeleteModal}
                        rows={certificates}
                        privileges={privileges}
                        getCertificates={getCertificates}
                      />
                      <TablePagination
                        rowsPerPageOptions={[20, 50, 100]}
                        count={count}
                        page={currentPage}
                        rowsPerPage={rowsPerPage}
                        onPageChange={(_, page) => handleChangePage(page)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        component="div"
                      />
                    </>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Certificates;
