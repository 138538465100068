import React, { useRef } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import ReactQuill from "react-quill";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  MenuItem,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const validationSchema = Yup.object().shape({
  question: Yup.string().required("Question is required"),
  answer: Yup.string().required("Answer is required"),
  productId: Yup.string().required("Product is required"),
});

function FaqProductForm(props) {
  const { faq, products, productId } = props;

  const quillRef = useRef(null);

  const onChangeQuill = () => {
    const length = quillRef.current?.unprivilegedEditor.getLength();
    if (length < 2) return false;
    else return true;
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await props.onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const initialValues =
    Object.keys(faq).length !== 0
      ? {
          question: faq.question,
          answer: faq.answer,
          productId: faq.productId,
        }
      : {
          question: "",
          answer: "",
          productId: productId !== "null" && productId ? productId : "",
        };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        setFieldValue,
      }) => (
        <Card mb={6}>
          <CardContent>
            {/* {status && status.sent && (
              <Alert severity="success" my={3}>
                [DEMO] Your data has been submitted successfully!
              </Alert>
            )} */}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <TextField
                  name="productId"
                  label="Select Product"
                  value={values.productId}
                  error={Boolean(touched.productId && errors.productId)}
                  fullWidth
                  helperText={touched.productId && errors.productId}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                  select
                >
                  {products &&
                    products.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </TextField>

                <TextField
                  name="question"
                  label="Question"
                  value={values.question}
                  error={Boolean(touched.question && errors.question)}
                  fullWidth
                  helperText={touched.question && errors.question}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />

                <Box sx={{ ".ql-container": { height: 200 }, my: 2 }}>
                  <Typography sx={{ my: 2 }} variant="h5">
                    Answer
                  </Typography>
                  <ReactQuill
                    theme="snow"
                    ref={quillRef}
                    name="answer"
                    style={{
                      height: "100%",
                      border: `${
                        Boolean(touched.answer && errors.answer)
                          ? "1px solid red"
                          : ""
                      }`,
                    }}
                    placeholder="Type here"
                    value={values.answer}
                    onChange={(value) => {
                      onChangeQuill()
                        ? setFieldValue("answer", value)
                        : setFieldValue("answer", "");
                    }}
                  />
                  {Boolean(touched.answer && errors.answer) && (
                    <Typography color="error" variant="caption">
                      Answer is required
                    </Typography>
                  )}
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  disabled={isSubmitting}
                >
                  Save changes
                </Button>
                <Button mt={3} onClick={() => props.navigate("/products/faq")}>
                  Cancel
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default FaqProductForm;
