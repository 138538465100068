import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setQuestionIndustry } from "../../redux/slices/questionIndustry";

export default function QuestionButton(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const btnText = useSelector((state) => state.questionIndustry.industryName);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value, text) => {
    if (value === "All Specific Questions") {
      dispatch(setQuestionIndustry({ industryId: null, industryName: text }));
    } else {
      dispatch(setQuestionIndustry({ industryId: value, industryName: text }));
    }
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant={props.variant ? props.variant : "contained"}
        color={props.color ? props.color : "primary"}
        startIcon={props.startIcon ? props.startIcon : null}
        endIcon={<KeyboardArrowDown />}
      >
        {btnText || props.btnText}
      </Button>
      <Menu
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSelect("All Specific Questions")}>
          All Specific Questions
        </MenuItem>
        {props.data.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => handleSelect(item.id, item.name)}
          >
            {item[props.title]}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
