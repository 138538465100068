import axios from "../utils/axios";

export const getAll = (filters) => {
  return axios.get(`faqs/all?${filters}`);
};

export const add = (data) => {
  return axios.post("faqs/create", data);
};

export const getOne = (id) => {
  return axios.get(`faqs/id/${id}`);
};

export const update = (data) => {
  return axios.put(`faqs/update`, data);
};

export const remove = (id) => {
  return axios.delete(`faqs/delete/id/${id}`);
};
