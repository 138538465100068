import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

//reducers
import counterReducer from "./slices/counter";
import authReducer from "./slices/auth";
import notificationReducer from "./slices/notificationUi";
import blogReducer from "./slices/blogSlice";
import privilegeReducer from "./slices/privilegeSlice";
import faqReducer from "./slices/faqSlice";
import faqIndustryReducer from "./slices/faqIndustry";
import questionIndustryReducer from "./slices/questionIndustry";

const reducers = combineReducers({
  counter: counterReducer,
  auth: authReducer,
  message: notificationReducer,
  blog: blogReducer,
  privileges: privilegeReducer,
  faq: faqReducer,
  faqIndustry: faqIndustryReducer,
  questionIndustry: questionIndustryReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "privileges"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
