import React, { useState } from "react";
import {
  Box,
  Modal,
  IconButton,
  Typography,
  Grid,
  Button,
  Chip,
  Stack,
  Divider,
} from "@mui/material";
import { Visibility, Check } from "@mui/icons-material";
import styled from "styled-components/macro";
import variants from "../../../theme/variants";
import { imageDefaultStyle } from "../../../theme/globalStyles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  py: 4,
  px: 5,
  borderRadius: "24px",
};

export default function ViewModal({ data }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <Visibility />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h2" align="center">
            {data.name}
          </Typography>

          <Typography
            variant="body1"
            align="center"
            sx={{
              pb: 3,
              borderBottom: `1px solid ${variants[0].palette.primary.main}`,
            }}
          >
            Created by {data.createdBy}
          </Typography>
          <Grid sx={{ mt: 2, mb: 5, mx: "auto" }} container spacing={6}>
            {data.icon && (
              <Grid item sm={12} md={6}>
                <Box sx={{ width: "100%", height: "250px" }}>
                  <img
                    src={data.icon.fileUrl}
                    style={{ ...imageDefaultStyle, objectFit: "contain" }}
                  />
                </Box>
                <Typography variant="h4" textAlign="center" mt={3} color="gray">
                  Icon
                </Typography>
              </Grid>
            )}
            {data.image && (
              <Grid item sm={12} md={6}>
                <Box sx={{ width: "100%", height: "250px" }}>
                  <img
                    src={data.image.fileUrl}
                    style={{ ...imageDefaultStyle, objectFit: "contain" }}
                  />
                </Box>
                <Typography variant="h4" textAlign="center" mt={3} color="gray">
                  Image
                </Typography>
              </Grid>
            )}
          </Grid>
          {data.forMenu && (
            <Box sx={{ mt: 5, mb: 3 }}>
              <Chip
                icon={<Check fontSize="16" />}
                label="Added To Menu"
                color="success"
              />
            </Box>
          )}

          <Box sx={{ mt: 2, mb: 3 }}>
            {data.shortDescription && (
              <Box>
                <Typography variant="h5">Short Description</Typography>
                <Typography variant="body1">{data.shortDescription}</Typography>
              </Box>
            )}
            {data.longDescription && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h5">Long Description</Typography>
                <Typography variant="body1">{data.longDescription}</Typography>
              </Box>
            )}
          </Box>
          <Divider />
          <Stack mt={3} direction="row" justifyContent={"end"}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleClose()}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
