import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  Box,
  Tooltip,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Edit, Delete } from "@mui/icons-material";

import ViewIndustry from "../../components/view-modals/ViewIndustry";
import { customPurple } from "../../../theme/variants";

//hover style chip
const sxProfessionalChips = {
  m: 1,
  cursor: "pointer",
  "&:hover": {
    background: customPurple[800],
  },
};

export default function IndustryTable(props) {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Slug</TableCell>
            {props.needProfession && (
              <TableCell align="left">Professions</TableCell>
            )}
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                <Box
                  display="flex"
                  alignItems="center"
                  gap={3}
                  justifyContent="start"
                >
                  {row.name}
                  {row.forMenu && (
                    <Chip color="success" label="Menu" size="small" />
                  )}
                  {row.forHomepage && (
                    <Chip color="secondary" label="Homepage" size="small" />
                  )}
                </Box>
              </TableCell>
              <TableCell align="left">{row.slug}</TableCell>
              {props.needProfession && (
                <TableCell align="left">
                  {row.childrenIndustries &&
                  row.childrenIndustries.length < 1 ? (
                    <Chip color="secondary" label="N/A" size="small" />
                  ) : (
                    <>
                      {row.childrenIndustries.map((industry, index) => (
                        <Tooltip key={industry.id ?? index} title="Edit">
                          <Chip
                            color="secondary"
                            label={industry.name}
                            size="small"
                            sx={sxProfessionalChips}
                            // onClick={
                            //   props.privileges.includes("INDUSTRY_UPDATE")
                            //     ? () =>
                            //         navigate(`/industry/edit/${industry.id}`)
                            //     : undefined
                            // }
                          />
                        </Tooltip>
                      ))}
                    </>
                  )}
                </TableCell>
              )}

              <TableCell align="center">
                <div style={{ display: "flex" }}>
                  <ViewIndustry data={row} />
                  {props.privileges.includes("INDUSTRY_UPDATE") ? (
                    <IconButton
                      onClick={() => {
                        if (props.needProfession) {
                          navigate(`/industry/edit/${row.id}`);
                        } else {
                          navigate(`/profession/edit/${row.id}`);
                        }
                      }}
                    >
                      <Edit />
                    </IconButton>
                  ) : null}
                  {props.privileges.includes("INDUSTRY_DELETE") ? (
                    <IconButton onClick={() => props.deleteIndustry(row.id)}>
                      <Delete />
                    </IconButton>
                  ) : null}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
