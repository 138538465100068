import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";

import Actions from "./Actions";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import DoughnutChart from "./DoughnutChart";
import Stats from "./Stats";
import Table from "./Table";

import params from "../../../utils/params";

import { getAgentsCount } from "../../../Apis/agentApi";
import { getAllContactsCount } from "../../../Apis/contactsApi";
import { getClaimsCount } from "../../../Apis/claimsApi";
import { getAllOpportunities } from "../../../Apis/opportunityApi";
import { getAll as getAllProducts } from "../../../Apis/productApi";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Default() {
  const [agentsNumber, setAgentsNumber] = useState(0);
  const [contactsNumber, setContactsNumber] = useState(0);
  const [claimsNumber, setClaimsNumber] = useState(0);
  const [opportunities, setOpportunities] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [products, setProducts] = useState([]);

  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();

  const fetchProducts = () => {
    getAllProducts(
      params({
        pageSize: 5,
        sortBy: "opportunityCount",
        ascOrDesc: "asc",
      })
    )
      .then((response) => setProducts(response.data.payload.content))
      .catch((err) => console.error("product error", err));
  };

  const agentsCount = () => {
    getAgentsCount()
      .then((response) => setAgentsNumber(response.data.payload))
      .catch((err) => console.error("agents count error", err));
  };

  const contactsCount = () => {
    getAllContactsCount()
      .then((response) => setContactsNumber(response.data.payload))
      .catch((err) => console.error("contacts count error", err));
  };

  const claimsCount = () => {
    getClaimsCount()
      .then((response) => setClaimsNumber(response.data.payload))
      .catch((err) => console.error("claims count error", err));
  };

  const fetchOpportunities = () => {
    getAllOpportunities(
      params({
        pageSize: 10,
        pageNo: 0,
        sortBy: "creationDate",
        ascOrDesc: "desc",
      })
    )
      .then((response) => {
        setTotalLeads(response.data.payload.numberOfElements);
        setOpportunities(response.data.payload.content);
      })
      .catch((err) => console.error("opportunity error", err));
  };

  useEffect(() => {
    agentsCount();
    contactsCount();
    claimsCount();
    fetchOpportunities();
    fetchProducts();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Cadre Insurance Dashboard
          </Typography>
          <Typography variant="subtitle1">
            {t("Welcome back")}, {user?.name}! {t("We've missed you")}.{" "}
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>

        <Grid item>
          <Actions />
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Agents"
            amount={agentsNumber}
            chip="Agents"
            percentagetext="+26%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Contacts"
            amount={contactsNumber}
            chip="Contacts"
            percentagetext="-14%"
            percentagecolor={red[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Claims"
            amount={claimsNumber}
            chip="Claims"
            percentagetext="+18%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Pending Claim Requests"
            amount="45"
            chip="Yearly"
            percentagetext="-9%"
            percentagecolor={red[500]}
            illustration="/static/img/illustrations/waiting.png"
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={8}>
          <LineChart />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DoughnutChart products={products} totalLeads={totalLeads} />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        {/* <Grid item xs={12} lg={4}>
          <BarChart />
        </Grid> */}
        <Grid item xs={12} lg={12}>
          <Table rows={opportunities} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Default;
