import React, { useState, useEffect } from "react";
import { Snackbar, Alert as MuiAlert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../redux/slices/notificationUi";

export default function SnackbarHOC({ children }) {
  const dispatch = useDispatch();

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const { open, message, type } = useSelector((state) => state.message);

  const closeMessage = () => {
    dispatch(clearMessage());
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={2000}
        onClose={closeMessage}
      >
        <Alert severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </>
  );
}
