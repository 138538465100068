import axios from "axios";
import { BASE_URL } from "../constants";

const headers = {
  "Content-Type": "application/json",
};

export const reset = (data) => {
  return axios.post(`${BASE_URL}auth/reset-password`, data, {
    headers: headers,
  });
};
