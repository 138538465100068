import { Visibility } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import variants from "../../../theme/variants";
import { stringEachWordFirstLetterUpperCase } from "../../../utils/textFormat";
import TimeFormat from "../../../utils/timeFormat";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  maxHeight: "95vh",
  overflowY: "scroll",
  boxShadow: 24,
  p: 10,
};

export default function ViewCertificate({ data }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <Visibility />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h2" align="center">
              {`Certificate Request`}
            </Typography>
          </Box>

          <Typography
            variant="body1"
            align="center"
            sx={{
              pb: 3,
              borderBottom: `1px solid ${variants[0].palette.primary.main}`,
            }}
          >
            Requested at {TimeFormat(data.creationDateTimeStamp)}
          </Typography>

          <Box sx={{ mt: 1, mb: 3 }}>
            <Grid mt={2} container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h5" mb={1}>
                    Business Name
                  </Typography>
                  <Typography variant="body1">{data.businessName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h5" mb={1}>
                    Business Email
                  </Typography>
                  <Typography variant="body1">{data.businessEmail}</Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                {" "}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h5" mb={1}>
                    Business Email
                  </Typography>
                  <Typography variant="body1">{data.businessEmail}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h5" mb={1}>
                    Phone
                  </Typography>
                  <Typography variant="body1">{data.phoneNumber}</Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                {" "}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h5" mb={1}>
                    Is this an urgent request?
                  </Typography>
                  <Typography variant="body1">
                    {data.urgent ? "Yes" : "No"}
                  </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h5" mb={1}>
                    Policy Number
                  </Typography>
                  <Typography variant="body1">{data.policyNumber}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h5" mb={1}>
                      Is there a Certificate Holder? 
                    </Typography>
                    <Typography variant="body1">
                      {data.certificateHolder ? "Yes" : "No"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6" mb={1}>
                      Certificate Holder Name
                    </Typography>
                    <Typography variant="body1">
                      {data.certficateHolderName ?? "N/A"}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h5" mb={1}>
                      Is an Additional Insured required?
                    </Typography>
                    <Typography variant="body1">
                      {data.additionalInsuredRequired ? "Yes" : "No"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6" mb={1}>
                      Additional Insured Content
                    </Typography>
                    <Typography variant="body1">
                      {data.additionalInsuredContent ?? "N/A"}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h5" mb={1}>
                      Do you require any special wording and/or description of
                      operations? 
                    </Typography>
                    <Typography variant="body1">
                      {data.anySpecialDescriptionRequired ? "Yes" : "No"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6" mb={1}>
                      Special Description
                    </Typography>
                    <Typography variant="body1">
                      {data.specialDescription ?? "N/A"}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h5" mb={1}>
                    Status
                  </Typography>
                  <Chip
                    size="small"
                    label={stringEachWordFirstLetterUpperCase(data.status)}
                    color={
                      data.status === "PROVIDED"
                        ? "success"
                        : data.status === "IN_REVIEW"
                        ? "warning"
                        : data.status === "IGNORED"
                        ? "error"
                        : "primary"
                    }
                  />
                </Box>
              </Grid>
            </Grid>
            {data.remark && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h5">Remarks</Typography>
                <Typography variant="body1">{data.remark}</Typography>
              </Box>
            )}
          </Box>

          <Divider />
          <Stack mt={3} direction="row" justifyContent={"end"}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleClose()}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
