import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
} from "@mui/material";

import {
  Delete,
  InsertDriveFileOutlined as FileIcon,
} from "@mui/icons-material";

import TimeFormat from "../../../utils/timeFormat";
import { stringEachWordFirstLetterUpperCase } from "../../../utils/textFormat";
import ViewCertificate from "../view-modals/ViewCertificate";
import UpdateCertificate from "../UpdateCertificate";
// import ViewClaims from "../view-modals/ViewClaims";

export default function CertificateTable(props) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Policy Number</TableCell>
            <TableCell align="left">Business Name</TableCell>
            <TableCell align="left">Business Email</TableCell>
            <TableCell align="left">Phone Number</TableCell>
            <TableCell align="left">Remarks</TableCell>
            <TableCell align="left">Requested At</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{row.policyNumber}</TableCell>
              <TableCell align="left">{row.businessName}</TableCell>
              <TableCell align="left">{row.businessEmail}</TableCell>
              <TableCell align="left">{row.phoneNumber}</TableCell>
              <TableCell align="left">{row.remark || "__"}</TableCell>
              <TableCell align="left">
                {TimeFormat(row.creationDateTimeStamp)}
              </TableCell>
              <TableCell align="left">
                {row.status && (
                  <Chip
                    label={stringEachWordFirstLetterUpperCase(row.status)}
                    color={
                      row.status === "PROVIDED"
                        ? "success"
                        : row.status === "IN_REVIEW"
                        ? "warning"
                        : row.status === "IGNORED"
                        ? "error"
                        : "primary"
                    }
                  />
                )}
              </TableCell>

              <TableCell align="center">
                <div style={{ display: "flex" }}>
                  <UpdateCertificate
                    data={row}
                    getCertificates={props.getCertificates}
                  />
                  <ViewCertificate data={row} />

                  <IconButton onClick={() => props.deleteClaim(row.id)}>
                    <Delete />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
