import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAll, remove } from "../../../Apis/industryApi";
import { useMessage } from "../../../hooks/useMessage";

import {
  CardContent,
  Grid,
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TablePagination,
  TextField,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Switch,
  FormGroup,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import params from "../../../utils/params";
import { debounce } from "../../../utils/debounce";

import IndustryTable from "../../components/tables/industryTable";

import ContentLoader from "../../components/contentLoader";
import NotFound from "../../components/NotFound";
import SortFilter from "../../components/SortFilter";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Professionals() {
  const [industries, setProfessionals] = useState([]);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [name, setName] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState("name");
  const [ascOrDesc, setAscOrDesc] = useState("asc");
  const navigate = useNavigate();
  const message = useMessage();
  const privileges = useSelector((state) => state.privileges.privileges);

  const getProfessionals = () => {
    setLoading(true);
    getAll(
      params({
        pageNo: currentPage,
        pageSize: rowsPerPage,
        name: name,
        ascOrDesc: ascOrDesc,
        sortBy: sortBy,
        onlyParents: "false",
      })
    )
      .then((response) => {
        console.log("get industries", response);
        setCurrentPage(response.data.payload.pageable.pageNumber);
        setRowsPerPage(response.data.payload.pageable.pageSize);
        setCount(response.data.payload.totalElements);
        setProfessionals(response.data.payload.content);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const showDeleteModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };
  const hideDeleteModal = () => setShowDelete(false);

  const submitDeleteIndustry = () => {
    remove(deleteId)
      .then((response) => {
        message("Profession Deleted", "success");
        hideDeleteModal();
        getProfessionals();
      })
      .catch((err) => message(err.response.data.message, "error"));
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setName(value);
  };

  //sorting
  const sortValues = [
    { label: "Name", value: "name" },
    { label: "Created At", value: "creationDate" },
  ];

  const handleSort = (e, type) => {
    const value = e.target.value;
    if (type === "ascOrDesc") {
      setAscOrDesc(value);
    } else {
      setSortBy(value);
    }
  };

  useEffect(
    () => getProfessionals(),
    [currentPage, rowsPerPage, name, sortBy, ascOrDesc]
  );
  return (
    <React.Fragment>
      <Helmet title="Professions | List " />
      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this profession?</DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={submitDeleteIndustry}
          >
            Delete
          </Button>
          <Button onClick={hideDeleteModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Professions
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Professions</Typography>
            <Typography>List</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          {privileges.includes("INDUSTRY_WRITE") ? (
            <Button
              onClick={() => navigate("/profession/add")}
              variant="contained"
              color="primary"
            >
              <AddIcon />
              Add Profession
            </Button>
          ) : null}
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <TextField
                  size="small"
                  label="Search by name"
                  variant="outlined"
                  sx={{ mr: 2 }}
                  onInput={(e) => debounce(() => handleSearch(e, "name"), 500)}
                />
                <SortFilter
                  sortBy={sortBy}
                  ascOrDesc={ascOrDesc}
                  sortValues={sortValues}
                  handleSort={handleSort}
                />
              </Box>
              {loading ? (
                <ContentLoader />
              ) : (
                <>
                  {industries.length < 1 ? (
                    <NotFound />
                  ) : (
                    <>
                      <IndustryTable
                        deleteIndustry={showDeleteModal}
                        rows={industries}
                        privileges={privileges}
                        needProfession={false}
                      />
                      <TablePagination
                        rowsPerPageOptions={[20, 50, 100]}
                        count={count}
                        page={currentPage}
                        rowsPerPage={rowsPerPage}
                        onPageChange={(_, page) => handleChangePage(page)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        component="div"
                      />
                    </>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Professionals;
