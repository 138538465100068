import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { signIn } from "../../Apis/authApi";
import storage from "redux-persist/lib/storage";
import moment from "moment";

const initialState = {
  isAuthorize: false,
  accessToken: null,
  refreshToken: null,
  user: null,
  tokenExpiration: null,
};

// export const signInAdmin = createAsyncThunk("auth/signIn", async (data) => {
//   const response = await signIn(data);
//   return response.data;
// });

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthorize = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
      state.tokenExpiration = null;
      storage.removeItem("persist:root");
    },
    setUser: (state, action) => {
      state.isAuthorize = true;
      state.accessToken = action.payload.payload.accessToken;
      state.refreshToken = action.payload.payload.refreshToken;
      state.user = action.payload.payload.user;
      state.tokenExpiration = moment().add(2, "days").toISOString();
    },
    updateUser: (state, action) => {
      state.isAuthorize = true;
      state.user = action.payload.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(signInAdmin.fulfilled, (state, action) => {
  //     state.isAuthorize = true;
  //     state.accessToken = action.payload.payload.accessToken;
  //     state.refreshToken = action.payload.payload.refreshToken;
  //     state.user = action.payload.payload.user;
  //   });
  // },
});

export const { logout, setUser, updateUser } = authSlice.actions;

export default authSlice.reducer;
