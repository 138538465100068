import React from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";

import {
  Checkbox,
  Box,
  Button as MuiButton,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  TextField as MuiTextField,
  Grid,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { spacing } from "@mui/system";

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  isIndustry: Yup.boolean(),
  parentIndustry: Yup.string().when("isIndustry", {
    is: false,
    then: Yup.string().required("Parent is required"),
  }),
  childrenIndustries: Yup.array().min(0, "").of(Yup.object()),
  shortDescription: Yup.string(),
  longDescription: Yup.string(),
  forMenu: Yup.boolean(),
  forHomepage: Yup.boolean(),
});
function AddIndustryForm(props) {
  const { industry, isIndustry, industries } = props;
  console.log("edit industry", industry, isIndustry);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await props.onSubmit(values);
      // resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const initialValues =
    Object.keys(industry).length !== 0
      ? {
          name: industry.name,
          parentIndustry: industry?.parentIndustryDetails?.id,
          childrenIndustries: industry.childrenIndustries || [],
          shortDescription: industry.shortDescription,
          longDescription: industry.longDescription,
          forMenu: industry.forMenu ?? false,
          forHomepage: industry.forHomepage ?? false,
        }
      : {
          name: "",
          parentIndustry: "",
          childrenIndustries: [],
          shortDescription: "",
          longDescription: "",
          isIndustry: isIndustry,
          forMenu: false,
          forHomepage: false,
        };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        setFieldValue,
      }) => (
        <Box mb={6}>
          {/* {status && status.sent && (
              <Alert severity="success" my={3}>
                [DEMO] Your data has been submitted successfully!
              </Alert>
            )} */}

          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item sm={12} md={6}>
                  <TextField
                    name="name"
                    label="Name"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                {isIndustry ? (
                  <Grid item sm={12} md={6}>
                    <Autocomplete
                      multiple
                      onOpen={handleBlur}
                      value={values.childrenIndustries}
                      onChange={(event, value) =>
                        setFieldValue("childrenIndustries", value)
                      }
                      options={industries}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          label="Select Professions"
                          placeholder="Professions"
                          error={Boolean(
                            touched.childrenIndustries &&
                              errors.childrenIndustries
                          )}
                          helperText={
                            touched.childrenIndustries &&
                            errors.childrenIndustries
                          }
                          my={2}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item sm={12} md={6}>
                    <TextField
                      name="parentIndustry"
                      label="Select Parent Industry"
                      value={values.parentIndustry}
                      error={Boolean(
                        touched.parentIndustry && errors.parentIndustry
                      )}
                      fullWidth
                      helperText={
                        touched.parentIndustry && errors.parentIndustry
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      select
                    >
                      {props.industries &&
                        props.industries.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>
              <TextField
                multiline
                rows={3}
                name="shortDescription"
                label="Short Description"
                value={values.shortDescription}
                error={Boolean(
                  touched.shortDescription && errors.shortDescription
                )}
                fullWidth
                helperText={touched.shortDescription && errors.shortDescription}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                my={2}
              />

              <TextField
                name="longDescription"
                label="Long Description"
                multiline
                rows={4}
                value={values.longDescription}
                error={Boolean(
                  touched.longDescription && errors.longDescription
                )}
                fullWidth
                helperText={touched.longDescription && errors.longDescription}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                my={2}
              />

              <Box display="flex" gap={20}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.forMenu}
                        onChange={(e) =>
                          setFieldValue("forMenu", e.target.checked)
                        }
                      />
                    }
                    label="Add to Menu"
                  />
                </FormGroup>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.forHomepage}
                        onChange={(e) =>
                          setFieldValue("forHomepage", e.target.checked)
                        }
                      />
                    }
                    label="Add to Homepage"
                  />
                </FormGroup>
              </Box>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                disabled={isSubmitting}
              >
                Save changes
              </Button>
              <Button
                mt={3}
                onClick={() => {
                  if (isIndustry) {
                    props.navigate("/industry");
                  } else {
                    props.navigate("/profession");
                  }
                }}
              >
                Cancel
              </Button>
            </form>
          )}
        </Box>
      )}
    </Formik>
  );
}

export default AddIndustryForm;
