import axios from "../utils/axios";

export const getAllSpecificQuestions = (filters) => {
  return axios.get(`industry/info-question/get/all?${filters}`);
};

export const createSpecificQuestion = (data) => {
  return axios.post("industry/info-question/create", data);
};

export const getSpecificQuestiongetById = (id) => {
  return axios.get(`industry/info-question/get/${id}`);
};

export const updateSpecificQuestion = (data) => {
  return axios.put(`industry/info-question/update`, data);
};

export const deleteSpecificQuestion = (id) => {
  return axios.delete(`industry/info-question/delete/${id}`);
};
