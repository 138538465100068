import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import AddFaqForm from "../../../forms/Faq/AddFaqForm";
import { useMessage } from "../../../../hooks/useMessage";

import { getOne, add, update } from "../../../../Apis/faqApi";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useDispatch } from "react-redux";
import { setFaqProduct } from "../../../../redux/slices/faqSlice";
import { getAllList as getAllProducts } from "../../../../Apis/productApi";
import { getOne as getProduct } from "../../../../Apis/productApi";
import FaqProductForm from "../../../forms/Faq/AddFaqProductForm";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function AddProductFaq() {
  const navigate = useNavigate();
  const message = useMessage();
  const params = useParams();
  const dispatch = useDispatch();
  const productId = params?.productId;
  const faqId = params?.id;
  const [faq, setFaq] = useState({});
  const [products, setProducts] = useState([]);

  const fetchFaq = () => {
    if (faqId) {
      getOne(faqId)
        .then((response) => {
          setFaq(response.data.payload);
        })
        .catch((err) => console.log("faq fetch error", err));
    }
  };

  const getProducts = () => {
    getAllProducts()
      .then((response) => setProducts(response.data.payload))
      .catch((err) => console.error("product failed", err));
  };

  const fetchProduct = (id) => {
    if (id) {
      getProduct(id).then((response) => {
        dispatch(
          setFaqProduct({
            productId: id,
            productName: response.data.payload.name,
          })
        );
      });
    }
  };

  const submitFaq = (values) => {
    // console.log("the values", values);
    const data = JSON.stringify({
      ...values,
      faqId: faqId ? faqId : null,
    });

    fetchProduct(values.productId);

    if (faqId) {
      update(data)
        .then((response) => {
          message("Faq updated", "success");
          return navigate("/products/faq");
        })
        .catch((err) => console.log("update error", err));
    } else {
      add(data)
        .then((response) => {
          message("Faq Created", "success");
          // console.log("faq response", response);
          return navigate("/products/faq");
        })
        .catch((err) => console.log("author error", err));
    }
  };

  useEffect(() => {
    fetchFaq();
    getProducts();
  }, []);

  return (
    <React.Fragment>
      <Helmet title={`${faqId ? "Edit" : "Add"} Faq`} />
      <Box display="flex" flexDirection="column">
        <Typography variant="h3" gutterBottom display="inline">
          {faqId ? "Edit" : "Add"} Faq
        </Typography>
        {/* <Typography
          color="secondary"
          variant="h5"
          gutterBottom
          display="inline"
        >
          Product: {product?.name}
        </Typography> */}
      </Box>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/products/faq">
          Faqs
        </Link>
        <Typography>{faqId ? "Edit" : "Add"}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <FaqProductForm
                navigate={navigate}
                faq={faq}
                products={products}
                productId={productId}
                onSubmit={submitFaq}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AddProductFaq;
