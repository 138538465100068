const textFieldInputProps = {
  style: {
    fontSize: "1.125rem",
    lineHeight: "1rem",
    padding: "10.5px 15px",
  },
};

const imageDefaultStyle = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
};

export { textFieldInputProps, imageDefaultStyle };
