import axios from "axios";
import { BASE_URL } from "../constants";

const options = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const signIn = (data) => {
  return axios.post(`${BASE_URL}auth/signin`, data, options);
};
