import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import IndustryForm from "../../forms/Industries/AddIndustryForm";
import {
  update,
  getOne,
  create,
  getAll,
  getProfessionalAll,
} from "../../../Apis/industryApi";
import { uploadFile } from "../../../Apis/fileApi";
import { useMessage } from "../../../hooks/useMessage";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import ImageUpload from "../../components/imageUploader";
import params from "../../../utils/params";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function AddProfession() {
  const navigate = useNavigate();
  const message = useMessage();
  const routeParams = useParams();
  const industryId = routeParams?.id;
  const [icon, setIcon] = useState("/placeholder-image.jpg");
  const [iconId, setIconId] = useState(null);
  const [image, setImage] = useState("/placeholder-image.jpg");
  const [imageId, setImageId] = useState(null);
  const [industry, setIndustry] = useState({});
  const [industries, setIndustries] = useState([]);

  const fetchIndustry = () => {
    if (industryId) {
      getOne(industryId).then((response) => {
        setIndustry(response.data.payload);
        if (response.data.payload.image) {
          setImageId(response.data.payload.image.id);
          setImage(response.data.payload.image.fileUrl);
        }
        if (response.data.payload.icon) {
          setIconId(response.data.payload.icon.id);
          setIcon(response.data.payload.icon.fileUrl);
        }
      });
    }
  };

  const fetchAllIndustries = () => {
    getAll(
      params({
        pageSize: 500,
        sortBy: "name",
        ascOrDesc: "asc",
        onlyParents: true,
      })
    )
      .then((response) => {
        setIndustries(response.data.payload.content);
      })
      .catch((err) => {
        console.log("industries error", err);
        message("Industries not loaded", "error");
      });
  };

  const submitIcon = (values) => {
    values.append("fileType", values.get("file").type);
    uploadFile(values)
      .then((response) => {
        console.log("icon uploaded", response);
        setIcon(response.data.payload.fileUrl);
        setIconId(response.data.payload.id);
        message("Profession icon uploaded", "success");
      })
      .catch((err) => console.log("icon", err));
  };

  const submitImage = (values) => {
    values.append("fileType", values.get("file").type);
    uploadFile(values)
      .then((response) => {
        console.log("image uploaded", response);
        setImage(response.data.payload.fileUrl);
        setImageId(response.data.payload.id);
        message("Profession image uploaded", "success");
      })
      .catch((err) => {
        message("Image upload error", "error");
        console.log("image upload failed", err.response);
      });
  };

  const submitIndustry = (values) => {
    let data = JSON.stringify({
      ...values,
      professionList: [],
      iconFileId: iconId,
      imageFileId: imageId,
    });

    if (industryId) {
      update(data, industryId)
        .then((response) => {
          message("Profession updated", "success");
          return navigate("/profession");
        })
        .catch((err) => message(err.response.data.message, "error"));
    } else {
      create(data)
        .then((response) => {
          message("Profession Created", "success");
          console.log("Profession create response", response);
          return navigate("/profession");
        })
        .catch((err) => message(err.response.data.message, "error"));
    }
  };

  useEffect(() => {
    fetchIndustry();
    fetchAllIndustries();
  }, []);

  return (
    <React.Fragment>
      <Helmet title={`${industryId ? "Edit" : "Add"} Profession`} />
      <Typography variant="h3" gutterBottom display="inline">
        {industryId ? "Edit" : "Add"} Profession
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/profession">
          Professions
        </Link>
        <Typography>{industryId ? "Edit" : "Add"}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Grid container spacing={6} mb={10}>
                <Grid item md={6} sm={12}>
                  <ImageUpload
                    image={icon}
                    imageType="INDUSTRY_ICON"
                    submitImage={submitIcon}
                    label="Upload Profession Icon"
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <ImageUpload
                    image={image}
                    imageType="INDUSTRY_IMAGE"
                    submitImage={submitImage}
                    label="Upload Profession Image"
                  />
                </Grid>
              </Grid>

              <IndustryForm
                navigate={navigate}
                industry={industry}
                industries={industries}
                onSubmit={submitIndustry}
                isIndustry={false}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AddProfession;
