import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  industryId: null,
  industryName: null,
};

export const faqIndustry = createSlice({
  name: "faqIndustry",
  initialState,
  reducers: {
    setFaqIndustry: (state, action) => {
      state.industryId = action.payload.industryId;
      state.industryName = action.payload.industryName;
    },
  },
});

export const { setFaqIndustry } = faqIndustry.actions;

export default faqIndustry.reducer;
